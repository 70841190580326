<template>
  <div class="referral">
    <h2 style="text-align: center">特殊困难群体服务转介表</h2>
    <Form :model="formData" :label-colon="true" :inline="true" :disabled="disabled">
      <FormItem style="width: 100%">
        <span slot="label" class="require">转介时间:</span>
        <DatePicker
          type="date"
          placeholder="请选择转介时间"
          style="width: 200px"
          v-model="formData.addReferralRo.referralDate"
        ></DatePicker>
      </FormItem>
      <Divider style="margin: 15px 0"/>
      <FormItem label="人员基本信息" style="width: 100%">
        <FormItem label="人员类别" style="width: 100%">
          <RadioGroup v-model="archiveDetailVo.difficultType">
            <Radio label="1" :disabled="true">低保低收入对象</Radio>
            <Radio label="2" :disabled="true">特困人员</Radio>
            <Radio label="3" :disabled="true">留守老年人</Radio>
            <Radio label="4" :disabled="true">独居孤寡老人</Radio>
            <Radio label="5" :disabled="true">困境儿童</Radio>
            <Radio label="6" :disabled="true">贫困重度残疾人</Radio>
            <Radio label="7" :disabled="true">其他</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="姓名" style="width: 24%">
          <Input :disabled="true" :maxlength="20" v-model.trim="archiveDetailVo.name" style="width: calc(100% - 100px)"></Input>
        </FormItem>
         <FormItem label="性别" style="width: 24%">
           <RadioGroup v-model="archiveDetailVo.sex">
            <Radio label="1" :disabled="true">男</Radio>
            <Radio label="2" :disabled="true">女</Radio>
           </RadioGroup>
        </FormItem>
         <FormItem label="年龄" style="width: 24%">
          <Input :disabled="true" :maxlength="20" v-model.trim="archiveDetailVo.age" style="width: calc(100% - 100px)"></Input>
        </FormItem>
         <FormItem style="width: 24%"></FormItem>
        <FormItem label="转介方" style="width: 24%">
           <span slot="label" class="require">转介方:</span>
          <Input :maxlength="20" v-model.trim="formData.addReferralOrgRo.orgName" style="width: calc(100% - 100px)"></Input>
        </FormItem>
        <FormItem label="联系地址" style="width: 24%">
          <Input :maxlength="20" v-model.trim="formData.addReferralOrgRo.addr" style="width: calc(100% - 100px)"></Input>
        </FormItem>
        <FormItem label="联系人" style="width: 24%">
          <Input :maxlength="20" v-model.trim="formData.addReferralOrgRo.contact" style="width: calc(100% - 100px)"></Input>
        </FormItem>
        <FormItem label="联系方式" style="width: 24%">
          <Input :maxlength="20" v-model.trim="formData.addReferralOrgRo.mobile" style="width: calc(100% - 100px)"></Input>
        </FormItem>
        <FormItem label="接受方" style="width: 24%">
                <span slot="label" class="require">接受方:</span>
          <Input :maxlength="20" v-model.trim="formData.addReceiverOrgRo.orgName" style="width: calc(100% - 100px)"></Input>
        </FormItem>
        <FormItem label="联系地址" style="width: 24%">
          <Input :maxlength="20" v-model.trim="formData.addReceiverOrgRo.addr" style="width: calc(100% - 100px)"></Input>
        </FormItem>
        <FormItem label="联系人" style="width: 24%">
          <Input :maxlength="20" v-model.trim="formData.addReceiverOrgRo.contact" style="width: calc(100% - 100px)"></Input>
        </FormItem>
        <FormItem label="联系方式" style="width: 24%">
          <Input :maxlength="20" v-model.trim="formData.addReceiverOrgRo.mobile" style="width: calc(100% - 100px)"></Input>
        </FormItem>
         <FormItem label="服务对象面临的主要困难" style="width: 100%">
          <Input v-model.trim="formData.addReferralRo.difficult" type="textarea" show-word-limit placeholder="请输入不超过400字" :maxlength="400" :rows="3" style="width: 100%"></Input>
        </FormItem>
         <FormItem label="镇（街）、村（居）、社工已提供的服务" style="width: 100%">
          <Input v-model.trim="formData.addReferralRo.hasService" type="textarea" show-word-limit placeholder="请输入不超过400字" :maxlength="400" :rows="3" style="width: 100%"></Input>
        </FormItem>
      </FormItem>
    </Form>

    <Divider style="margin: 15px 0"/>

    <Form :model="formData" :label-colon="true" :inline="true" :disabled="disabled">
      <FormItem label="转介需求" style="width: 100%">
            <FormItem label="转介原因" style="width: 100%">
          <Input v-model.trim="formData.addReferralRo.referralCause" type="textarea" show-word-limit placeholder="请输入不超过400字" :maxlength="400" :rows="3" style="width: 100%"></Input>
        </FormItem>
            <FormItem label="服务建议" style="width: 100%">
          <Input v-model.trim="formData.addReferralRo.serviceProposal" type="textarea" show-word-limit placeholder="请输入不超过400字" :maxlength="400" :rows="3" style="width: 100%"></Input>
        </FormItem>
            <FormItem label="其他说明" style="width: 100%">
          <Input v-model.trim="formData.addReferralRo.remark" type="textarea" show-word-limit placeholder="请输入不超过400字" :maxlength="400" :rows="3" style="width: 100%"></Input>
        </FormItem>
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  props: ['infoId',"formDisabled","archiveId"],
  data() {
    return {
        formData: {addReceiverOrgRo:{},addReferralOrgRo:{},addReferralRo:{}},
        disabled: false,
        archiveDetailVo: {}
    };
  },
  methods: {
    save(name) {   //从父组件调用save
    if(!this.formData.addReferralRo.referralDate) {
      this.$Message.error({
        background: true,
        content: "请选择转介时间"
      })
      return;
    }
    if(!this.formData.addReferralOrgRo.orgName) {
      this.$Message.error({
        background: true,
        content: "请输入转介方"
      })
      return;
    }
    if(!this.formData.addReceiverOrgRo.orgName) {
      this.$Message.error({
        background: true,
        content: "请输入接受方"
      })
      return;
    }
    if(this.formData.addReferralOrgRo.mobile && !/^((0\d{2,3}-\d{7,8})|(^1[3-9]\d{9}))$/.test(this.formData.addReferralOrgRo.mobile)) {
       this.$Message.error({
        background: true,
        content: "请输入正确的转介方联系方式"
      })
      return;
    }
    if(this.formData.addReceiverOrgRo.mobile && !/^((0\d{2,3}-\d{7,8})|(^1[3-9]\d{9}))$/.test(this.formData.addReceiverOrgRo.mobile)) {
       this.$Message.error({
        background: true,
        content: "请输入正确的接受方联系方式"
      })
      return;
    }
    if(this.formData.addReferralOrgRo.mobile == this.formData.addReceiverOrgRo.mobile && this.formData.addReceiverOrgRo.mobile != '' && this.formData.addReceiverOrgRo.mobile != undefined) {
       this.$Message.error({
        background: true,
        content: "转介方与接受方的手机号不能相同"
      })
      return;
    }
    if(this.formData.addReferralRo.referralDate) {  //转换转介日期格式
       this.formData.addReferralRo.referralDate = this.$core.formatDate(new Date(this.formData.addReferralRo.referralDate),"yyyy-MM-dd")
    }
      if(name == 'demand') {   //从需求转介
        this.$post('/archives/api/pc/referral/add', {
        ...this.formData,
        addReferralRo: {
          ...this.formData.addReferralRo,

          archiveId: this.infoId
        }
      },{"Content-Type":"application/json"}).then(res => {
        if(res.code == 200) {
          this.$Message.success({
            background: true,
            content: "保存成功"
          })
          this.$emit('saveSuccess');
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      })
      }else if(name == 'referral') {  //转介
         this.$post('/archives/api/pc/referral/update', {
           updateReferralOrgRo: this.formData.addReferralOrgRo,
           updateReceiverOrgRo: this.formData.addReceiverOrgRo,
        updateReferralRo: {
          ...this.formData.addReferralRo,
        }
      },{"Content-Type":"application/json"}).then(res => {
        if(res.code == 200) {
          this.$Message.success({
            background: true,
            content: "保存成功"
          })
          this.$emit('saveSuccess');
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      })
      }
    }
  },
  created() {
    if(this.formDisabled != undefined) {
      this.disabled = true;
    }else {
      this.disabled = false;
    }
    if(this.infoId != undefined && this.archiveId == undefined) {   //获取已转介信息
      this.$Message.loading("正在加载数据...")
      this.$post('/archives/api/pc/referral/queryByArchiveId',{
        archiveId: this.infoId
      }).then(res => {
        this.$Message.destroy();
        if(res.code == 200 && res.data) {
          this.formData = {
            addReceiverOrgRo: res.data.receiverOrgVo,
            addReferralOrgRo: res.data.referralOrgVo,
            addReferralRo: res.data.referralVo
          }
          this.archiveDetailVo = res.data.archiveDetailVo;
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      })
    }
    if(this.infoId != undefined && this.archiveId != undefined) {  //需求评估=》转介的基本信息
       this.$Message.loading("正在加载数据...")
      this.$post('/archives/api/pc/archiveUser/queryByArchiveId',{
        archiveId: this.infoId
      }).then(res => {
        this.$Message.destroy();
        if(res.code == 200 && res.data) {
          this.archiveDetailVo = res.data;
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      })
    }
  }
};
</script>
    
<style scoped lang='less'>
.referral {
  padding: 0 10px 20px;
}
.ivu-form-item {
    .ivu-form-item {
        padding: 0 25px;
    }
}
.ivu-form-item{
   width: 100%;
   margin-bottom: 15px;
    .ivu-input-wrapper {
      width: 200px;
      /deep/input {
        border: 0;
        border-bottom: 1px solid #65656c;
        border-radius: 0;
      }
      /deep/input:hover,/deep/input:focus {
           border: 0;
           border-bottom: 1px solid #65656c;
           box-shadow: none;
        }
        /deep/.ivu-input[disabled], fieldset[disabled] .ivu-input {
        background-color: #fff!important;
        color: #515a6e;
    }
    }
  }
    /deep/.ivu-radio-disabled .ivu-radio-inner,/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner {
      border: 1px solid #515a6e
    }
    /deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner:after,/deep/.ivu-radio-disabled .ivu-radio-inner:after {
      border-color: #515a6e;
    }
    /deep/.ivu-radio-disabled .ivu-radio-inner:after {
      background-color: #888;
    }
    /deep/.ivu-checkbox-disabled+span {
      color: #515a6e;
    }
    /deep/.ivu-date-picker-rel {
      .ivu-input-disabled {
        background-color: #fff!important;
      }
    }
  .require {
     &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
  }
</style>