<template>
  <LiefengContent>
    <template v-slot:title>转介服务</template>
    <template v-slot:toolsbarRight>
      <Form
        :model="searchForm"
        :label-colon="true"
        style="width: 624px"
        :inline="true"
      >
        <FormItem label="姓名">
          <Input
            :maxlength="20"
            v-model.trim="searchForm.name"
            placeholder="请输入姓名"
            style="width: 150px"
          ></Input>
        </FormItem>
        <FormItem label="手机号">
          <Input
            :maxlength="20"
            v-model.trim="searchForm.mobile"
            placeholder="请输入手机号"
            style="width: 150px"
          ></Input>
        </FormItem>
        <Button
          type="primary"
          icon="ios-search-outline"
          @click="search"
          style="margin-right: 10px"
          >查询</Button
        >
        <Button type="success" @click="reset" icon="ios-refresh">重置</Button>
        <Drawer
          title="查询条件"
          v-model="selectQuery"
          width="320"
          :styles="{
            height: 'calc(100% - 55px)',
            overflow: 'auto',
            paddingBottom: '53px',
            position: 'static',
          }"
        >
          <Form :model="searchForm" :label-colon="true">
            <FormItem label="姓名">
              <Input
                :maxlength="20"
                v-model.trim="searchForm.name"
                placeholder="请输入姓名"
              ></Input>
            </FormItem>
            <FormItem label="手机号">
              <Input
                :maxlength="20"
                v-model.trim="searchForm.mobile"
                placeholder="请输入手机号"
              ></Input>
            </FormItem>
            <FormItem label="档案编号">
              <Input
                :maxlength="20"
                v-model.trim="searchForm.archiveNum"
                placeholder="请输入档案编号"
                style="width: 100%"
              />
            </FormItem>
            <FormItem label="所属社区">
              <Input
                :maxlength="20"
                v-model.trim="searchForm.orgName"
                placeholder="请输入所属社区"
              ></Input>
            </FormItem>
            <FormItem label="分类">
              <Select v-model="searchForm.difficultType">
                <Option
                  v-for="item in classificationList"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="分级">
              <Select v-model="searchForm.difficultLevel">
                <Option
                  v-for="item in levelList"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="接受方">
              <Input
                :maxlength="20"
                v-model.trim="searchForm.receiverOrgName"
                placeholder="请输入接受方"
              ></Input>
            </FormItem>
            <FormItem label="转介方">
              <Input
                :maxlength="20"
                v-model.trim="searchForm.referralOrgName"
                placeholder="请输入转介方"
              ></Input>
            </FormItem>
          </Form>
          <div class="demo-drawer-footer">
            <Button style="margin-right: 8px" @click="selectQuery = false"
              >取消</Button
            >
            <Button type="primary" @click="search">查询</Button>
          </div>
        </Drawer>
      </Form>
      <Button
        type="primary"
        @click="$router.push('/archivesindex')"
        icon="ios-arrow-back"
        >返回</Button
      >
      <Icon
        type="md-more"
        @click="selectQuery = true"
        style="font-size: 24px; line-height: 32px; cursor: pointer"
        title="更多查询条件"
      />
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
      ></LiefengTable>
      <!-- 修改 -->
      <LiefengModal
        title="修改转介服务登记表"
        :value="changeStatus"
        @input="changeStatusFn"
        :fullscreen="true"
      >
        <template v-slot:contentarea>
          <Referral
            ref="referral"
            v-if="changeStatus"
            :infoId="infoId"
            @saveSuccess="saveSuccess"
          ></Referral>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="info"
            @click="
              cancelModelTip = true;
              changeStatusFn(false);
            "
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="saveChange">保存</Button>
        </template>
      </LiefengModal>
      <!-- 查看 -->
      <LiefengModal
        title="查看转介服务登记表"
        :value="seeStatus"
        @input="seeStatusFn"
        :fullscreen="true"
      >
        <template v-slot:contentarea>
          <Referral
            v-if="seeStatus"
            :infoId="infoId"
            :formDisabled="true"
          ></Referral>
        </template>
        <template v-slot:toolsbar>
          <Button type="primary" @click="seeStatusFn(false)">关闭</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/archivesreferral")
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import Referral from "@/views/archives/childrens/referral";
export default {
  data() {
    return {
      selectQuery: false, //搜索抽屉
      searchForm: {},
      //搜索分类
      classificationList: [
        { label: "低保低收入对象", value: "1" },
        { label: "特困人员", value: "2" },
        { label: "留守老年人", value: "3" },
        { label: "独居孤寡老人", value: "4" },
        { label: "困境儿童", value: "5" },
        { label: "贫困重度残疾人", value: "6" },
        { label: "其他", value: "7" },
      ],
      //搜索分级
      levelList: [
        { label: "一级", value: "1" },
        { label: "二级", value: "2" },
        { label: "三级", value: "3" },
        { label: "四级", value: "4" },
      ],
      talbeColumns: [
        {
          title: "档案编号",
          key: "archiveNum",
          minWidth: 100,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          width: 120,
          align: "center",
        },
        {
          title: "手机号",
          key: "mobile",
          width: 150,
          align: "center",
        },
        {
          title: "所属社区",
          key: "orgName",
          minWidth: 150,
          align: "center",
        },
        {
          title: "分类/人员类别",
          key: "difficultType",
          minWidth: 150,
          align: "center",
        },
        {
          title: "分级",
          key: "difficultLevel",
          width: 120,
          align: "center",
        },
        {
          title: "接受方",
          key: "receiverOrgName",
          width: 180,
          align: "center",
        },
        {
          title: "转介方",
          key: "referralOrgName",
          width: 120,
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 140,
          render: (h, params) => {
            return h("div", [
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true,
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "info",
                        size: "small",
                        icon: "ios-arrow-down",
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list",
                    },
                    [
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.infoId = params.row.archiveId;
                              this.changeStatus = true;
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "修改转介信息"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.infoId = params.row.archiveId;
                              this.seeStatus = true;
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "查看转介信息"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.deletedRow(params.row.archiveId);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "删除"
                      ),
                    ]
                  ),
                ]
              ),
            ]);
          },
        },
      ],
      tableData: [],
      total: 0,
      page: 1,
      loading: false,
      pageSize: 20,

      //修改
      changeStatus: false,
      cancelModelTip: true, //true代表关闭时弹窗提示
      infoId: "",

      // 查看
      seeStatus: false,
    };
  },
  methods: {
    //删除
    deletedRow(archiveId) {
      this.$Modal.warning({
        title: "温馨提示",
        content: "您正在删除转介信息，是否继续？",
        onOk: () => {
          this.$post('/archives/api/pc/referral/deleteReferral',{
            archiveId
          }).then(res => {
            if(res.code == 200) {
              this.$Message.success({
                background: true,
                content: "删除成功"
              })
              this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize
              })
            }else {
              this.$Message.error({
                background: true,
                content: res.desc
              })
            }
          }).catch(err => {
            this.$Message.error({
                background: true,
                content: "删除失败"
              })
          })
        },
      });
    },
    //   查看
    seeStatusFn(status) {
      this.seeStatus = status;
    },
    //修改
    changeStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          className: "vertical-center-modal",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.changeStatus = status;
          },
        });
      } else {
        this.changeStatus = status;
      }
    },
    //修改保存成功后执行
    saveSuccess() {
      this.cancelModelTip = false;
      this.changeStatus = false;
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    saveChange() {
      this.$refs.referral.save("referral");
    },
    //重置
    reset() {
      this.searchForm = {};
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    //搜索
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
      this.$post("/archives/api/pc/referral/queryByPage", {
        ...this.searchForm,
        page: obj.page,
        pageSize: obj.pageSize,
        oemCode: parent.vue.oemInfo.oemCode,
        operatorId: parent.vue.loginInfo.userinfo.custGlobalId,
        operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item, index) => {
              switch (item.difficultType) {
                case "1":
                  item.difficultType = "低保低收入对象";
                  break;
                case "2":
                  item.difficultType = "特困人员";
                  break;
                case "3":
                  item.difficultType = "留守老年人";
                  break;
                case "4":
                  item.difficultType = "独居孤寡老人";
                  break;
                case "5":
                  item.difficultType = "困境儿童";
                  break;
                case "6":
                  item.difficultType = "贫困重度残疾人";
                  break;
                case "7":
                  item.difficultType = "其他";
                  break;
              }
              switch (item.difficultLevel) {
                case "1":
                  item.difficultLevel = "一级";
                  break;
                case "2":
                  item.difficultLevel = "二级";
                  break;
                case "3":
                  item.difficultLevel = "三级";
                  break;
                case "4":
                  item.difficultLevel = "四级";
                  break;
              }
            });
            this.tableData = res.dataList;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
            this.page = res.currentPage;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengTable,
    LiefengModal,
    Referral,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-form-item {
  margin-bottom: 0;
  width: 210px;
}
/deep/.ivu-drawer-body {
  .ivu-form-item {
    width: 100%;
  }
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
/deep/.ivu-modal-close {
  display: none;
}
</style>